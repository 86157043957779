<template>
    <div class="content-wrapper">
        <div class="items">
            <div class="container mt-5">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        
                        <div class="card">
                            <div class="card-header">
                                Laporan Kartu Stok
                            </div>
                             
                            <div>
                                    <a href="#" v-on:click.prevent="showSuccessToast">Display toast</a>
                            </div>
                            <div class="card-body">
                                                                             
                                <div class="form-row">                                                                             
                                    <div class="form-group col-md-6">

                                            <label for="notrans">Dari  </label>
                                            <div class="input-group-append">
                                                <datepicker v-model="fields.tgl1" id="tgltrans1"></datepicker>
                                            </div>        
                                    </div>
                                    <div class="form-group col-md-6">

                                            <label for="notrans">Sampai  </label>
                                            <div class="input-group-append">
                                                <datepicker v-model="fields.tgl2" id="tgltrans2"></datepicker>
                                            </div>        
                                    </div>
                                    <div class="form-group col-md-6">
                                         <label for="notrans">Gudang  </label>
                                    <b-input-group>
                                                              <input type="text" class="form-control" disabled="true" v-model="fields.gdg1" />
                                                              <b-button variant="success" @click="showGdg(index)">...</b-button>
                                    </b-input-group>
                                    </div>
                                    
                                    <div class="form-group col-md-6">
                                        <label for="notrans">Gudang  </label>
                                    <b-input-group>
                                                              <input type="text" class="form-control" disabled="true" v-model="fields.gdg2" />
                                                              <b-button variant="success" @click="showGdg(index)">...</b-button>
                                    </b-input-group>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="notrans">Item  </label>
                                    <b-input-group>
                                                              <input type="text" class="form-control" disabled="true" v-model="fields.item1" />
                                                              <b-button variant="success" @click="showGdg(index)">...</b-button>
                                    </b-input-group>
                                    </div>
                                    
                                    <div class="form-group col-md-6">
                                        <label for="notrans">  </label>
                                    <b-input-group>
                                                              <input type="text" class="form-control" disabled="true" v-model="fields.item2" />
                                                              <b-button variant="success" @click="showGdg(index)">...</b-button>
                                    </b-input-group>
                                    </div>
                                </div>    
                                <div class="row">
                                    <div class="form-group " >
                                        <div class="d-flex justify-content-center">
<!--                                                 <CButton
                        style="margin-right:5px" size="sm" color="primary" @click="report(1)">
                            <CIcon name="cil-check-circle" /> View Report
                        </CButton> -->
                                        <button type="submit" class="btn btn-primary" @click="showReport(1)">Submit</button>
                                        <!-- <a @click="$router.go(-1)" class="btn btn-danger">Back</a> -->
                                        <a @click="$router.push({name: 'rep_kartu_stok'})" class="btn btn-danger">Back</a>
                                        </div>
                                     </div>   
                                </div>    
                            </div>
                                <div class="card-footer">
                                    
                                     <div style="width: 100%; height: 500px;">
                                        <embed v-if="src" :src="src" type="application/pdf" width="100%" height="100%" />
                                    </div>
                                </div>
                                 
                        </div>
                    </div>
                </div>
            </div>
       
        </div>    
    </div>    

</template>


<script>
    //import axios from 'axios'
    import Datepicker from 'vuejs-datepicker'
    import { defaultFields } from "./helpers";
    export default {
        components: {
             Datepicker,
            },
        data() {
            return {
                fields: defaultFields(),
                items: [],
                name : this.$route.name,
                menu : this.$route.meta.menu,
                path : this.$route.path,
                sidebar : this.$router.options.routes,
                path1 : this.$router.currentRoute.path,
                src: null,
            }
        },
        beforeMount: async function() {
            //this.getListdata();
        },
        created() {
            /* var headers = { 
                "Access-Control-Allow-Origin": "*" 
            }  */
            /* var headers= {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, item, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            } */
            /* axios.get('http://localhost:8000/api/items', {}, headers).then(response => {
                this.items = response.data.data;
               // console.log(this.items);
            }); */
             //this.getServices();
        },
         methods: {
             async getServices(route = this.$route) {
                this.isLoading = true;
                console.log(route);
                console.log('item');
                const params = { 
                    page: 's',
                    limit: 10,
                };
                var response = await this.apiGets(`atk_in`, { params });
                if(response.status == 200) {
                    //this.lists = { ...response.data.data };
                    this.items = { ...response.data.data };
                }

                this.isLoading = false;
            },
            async getListdata(route = this.$route) {
                    this.isLoading = true;
                     console.log(route);
                      const params = { 
                    page: 's',
                    limit: 10,
                };
                    var response = await this.apiGets(`/api/sale`, { params });
                    if(response.status == 200) {
                        this.lists = { ...response.data.data };
                    }

                    this.isLoading = false;
                },
            itemDelete(id)
            {
               this.$swal({
                    title: "Anda Yakin Akan Menghapus data?",            
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Ya',
                    cancelButtonColor: '#3085d6',
                    cancelButtonText: "Batal"
                
                }).then( async  result => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        var response = await this.apiDeletes(`atk_in/${id}`);
                        if(response.status == 200) {
                            this.items = { ...response.data.data };
                        }
                        this.getServices();

                        this.isLoading = false;
                        console.log(result);
                    }                        
                    console.log(id);
          
                 
                });
            },
             showSuccessToast() {
                    //this.$toastr.s("Success", "Hello world");
                    //this.$swal("Success", "Hello world");
                    this.$swal({
                        title: 'What is your Name?',
                        input: 'text',
                        border: '1px solid #F0E1A1',
                        //background: 'rgba(43, 165, 137, 0.45)'
                        backdrop: "rgba(43, 165, 137, 0.45)",
                        //background: '#4962B3'
                        //background: "black",
                        //backdrop: "linear-gradient(yellow, orange)",
                      
                        });
                     
                   
                 },
            async showReport(action = 1) {
                try {
                    this.fields.s_export_to = ( action == 3? 'XLS': 'PDF' );
                    var newFields = {...this.fields}
                    if (newFields.s_group_name == "NONE") newFields.s_group_name = ""
                    const response = await this.apiReports('Inventory/ReportStock/_Report', newFields);
                    
                    switch (action) {
                        case 1:
                            this.showFile(response.data);
                            break;
                    
                        case 2:
                            this.downloadFile(response.data);
                            break;
                        
                        case 3:
                            this.downloadFile(response.data, 'application/vnd.ms-excel', 'LAPORAN KARTU STOK.xls');
                            break;

                        default:
                            break;
                    }
                    
                    
                } catch (error) {
                    console.log(error)
                }
            },
            async showFile(myBlob) {
                let blob = new Blob([myBlob], { type: 'application/pdf' });
                this.src = window.URL.createObjectURL(blob);
                this.$store.state[this.$router.currentRoute.path+'src'] = this.src;
            },
        }
    }
</script>