
//import moment from 'moment'
const defaultFields = () => {
	var today = new Date();
	var test =  vs.formatInputDate(today);
	var start = new Date(today.getFullYear(), today.getMonth(), 1);
	//var test=1;
	console.log(today);
	console.log(test);
	return {
		//"tgl":  this.$vm.formatInputDate(today),
		//"tgl":  moment(today).format("YYYY-MM-DD"),
		//"tgl":  this.global.vm.formatInputDate(today),
		notrans: '',
		"tgl1":  vs.formatInputDate(start),
		"tgl2":  vs.formatInputDate(today),
		//"tgl2":  vm.tes,
		name: '',
		ket: '',
		qty: 0,
		price: 0,
		tipe: '',
		detail: []
	}
	
};

/* const defaultFields2 = () => ({
	name: '',
	qty: 0,
	price: 0,
	code: null,
	items: []
}) */
const defaultFieldItems = () => ({
	gdg_id: 0,
	item_id: 0,
	qty: 0,
	price: 0,
})

export {
    defaultFields,
	defaultFieldItems
}